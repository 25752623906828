import {
  Box,
  Button,
  CloseIcon,
  IconButton,
  LoadingButton,
  Modal,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { StateStorage } from "../../../../models/stateStorage";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "325px",
  padding: "28px 32px",
  bgcolor: "white",
  borderRadius: "22px",
};

export type ModalConfirmChangeOwnerProps = {
  isOpen: boolean;
  handleClose: () => void;
  onSave: () => void;
  email: string;
};

export const ModalConfirmChangeOwner = ({
  isOpen,
  handleClose,
  onSave,
  email,
}: ModalConfirmChangeOwnerProps) => {
  const { isChangingOwner, ownerIdSelected } = useSelector(
    (state: StateStorage) => state.accessManagement
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
            width: "100%",
          }}
        >
          <IconButton
            sx={{
              alignSelf: "end",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            textAlign="center"
            variant="h5"
            color={enerbitColors.primary.main}
          >
            ¿Está seguro que quiere cambiar de propietario?
          </Typography>
          <Box
            sx={{
              paddingTop: "12px",
              paddingBottom: "12px",
              width: "100%",
            }}
            textAlign="center"
          >
            <Typography variant="body1" color={enerbitColors.neutral[700]}>
              El nuevo propietario será:
            </Typography>
            <Typography
              variant="body1"
              color={enerbitColors.neutral[700]}
              fontWeight="bold"
            >
              {email}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "16px",
            }}
          >
            <Button variant="text" onClick={handleClose}>
              Cancelar
            </Button>
            <LoadingButton
              color="secondary"
              variant="contained"
              disabled={
                isChangingOwner ||
                !ownerIdSelected ||
                ownerIdSelected.length == 0
              }
              loading={isChangingOwner}
              onClick={() => {
                onSave();
              }}
            >
              Cambiar
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
