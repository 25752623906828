import { PrivateRoute, ThemeConfig } from "@enerbit/base";
import { Provider } from "react-redux";
import { PropsAccessManagement } from "../models/accessManagement";
import ServiceAccount from "../pages/serviceAccount/ServiceAccount";
import { store } from "../store/store";

export default function Routers({
  idServiceAccount,
  shouldShowNewUser,
  showDetail,
  onClickDetail,
}: PropsAccessManagement) {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <PrivateRoute>
          <ServiceAccount
            shouldShowNewUser={shouldShowNewUser}
            idServiceAccount={idServiceAccount}
            showDetail={showDetail}
            onClickDetail={onClickDetail}
          />
        </PrivateRoute>
      </ThemeConfig>
    </Provider>
  );
}
