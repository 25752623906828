import {
  Box,
  CircularProgress,
  CloseIcon,
  FormGroup,
  IconButton,
  InputLabel,
  LoadingButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingUser,
  ownerIdSelected,
  setOwnerIdSelected,
  usersServiceAccount,
} from "../../../../features/accessManagement/accessManagement";
import { RolesName } from "../../../../helpers/helperUsers";
import { StateStorage } from "../../../../models/stateStorage";
import { AppDispatch } from "../../../../store/store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "579px",
  padding: "48px 52px",
  bgcolor: "white",
  borderRadius: "22px",
};

export type ModalChangeOwnerProps = {
  isOpen: boolean;
  handleClose: () => void;
  onSave: () => void;
};

export const ModalChangeOwner = ({
  isOpen,
  handleClose,
  onSave,
}: ModalChangeOwnerProps) => {
  const isLoading = useSelector(isLoadingUser);
  const users = useSelector(usersServiceAccount);
  const ownerId = useSelector(ownerIdSelected);
  const dispatch = useDispatch<AppDispatch>();

  const { isChangingOwner } = useSelector(
    (state: StateStorage) => state.accessManagement
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
            width: "100%",
          }}
        >
          <IconButton
            sx={{
              alignSelf: "end",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" color={enerbitColors.primary.main}>
            Cambiar propietario
          </Typography>
          <Box
            sx={{
              paddingTop: "12px",
              paddingBottom: "12px",
              width: "100%",
            }}
          >
            <FormGroup sx={{ width: "100%" }}>
              <InputLabel shrink className="Input-label">
                Nuevo propietario
              </InputLabel>
              <TextField
                fullWidth
                select
                className="TextField-without-border-radius"
                id="userId"
                name="userId"
                variant="outlined"
                value={ownerId}
                onChange={(e) => {
                  const userId = e.target.value;
                  if (userId) {
                    dispatch(setOwnerIdSelected(userId));
                  }
                }}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  users
                    ?.filter((value) => value.rol.name != RolesName.owner)
                    .map((user) => {
                      return (
                        <MenuItem key={user.user_id} value={user.user_id}>
                          {user.name}
                        </MenuItem>
                      );
                    }) ?? (
                    <Typography variant="body1">
                      No hemos encontrado ningún resultado
                    </Typography>
                  )
                )}
              </TextField>
            </FormGroup>
          </Box>
          <LoadingButton
            sx={{ alignSelf: "flex-end" }}
            color="secondary"
            variant="contained"
            disabled={isChangingOwner || !ownerId || ownerId.length == 0}
            loading={isChangingOwner}
            onClick={() => {
              onSave();
            }}
          >
            Guardar
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
