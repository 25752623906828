import {
  AddCircleOutlineOutlinedIcon,
  Alert,
  Box,
  Button,
  CloseIcon,
  Collapse,
  CustomModalTypes,
  DeleteOutlineIcon,
  EditOutlinedIcon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@enerbit/base";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOwner,
  setOwnerIdSelected,
  setRelationshipSelectId,
  usersServiceAccount,
} from "../../../../features/accessManagement/accessManagement";
import { translateRoles } from "../../../../helpers/helperUsers";
import {
  PropsAccessManagement,
  TableAccessManagement,
} from "../../../../models/accessManagement";
import { StateStorage } from "../../../../models/stateStorage";
import { AppDispatch } from "../../../../store/store";
import ModalAction from "../reusable/ModalAction";
import { ModalChangeOwner } from "./ModalChangeOwner";
import { ModalConfirmChangeOwner } from "./ModalConfirmChangeOwner";
import ModalNewUser from "./ModalNewUser";

const SectionServiceUsers = ({
  idServiceAccount,
  shouldShowNewUser,
  showDetail,
  onClickDetail,
}: PropsAccessManagement) => {
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector(usersServiceAccount);
  const [isChangeOwnerModalOpen, setIsChangeOwnerModalOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isConfirmChangeOwnerModalOpen, setIsConfirmChangeOwnerModalOpen] =
    useState(false);
  const { ownerIdSelected, isSuccessfullyCompletedOwner } = useSelector(
    (state: StateStorage) => state.accessManagement
  );

  const mappUsers = () => {
    let array: TableAccessManagement[] = [];
    if (users) {
      users.forEach((element) => {
        array.push({
          email: element.username,
          name: element.name,
          rol: element.rol.name,
          id: element.id,
          userId: element.user_id,
        });
      });
    }
    return array;
  };
  const deleteModal = useRef<CustomModalTypes>(null);
  const newModal = useRef<CustomModalTypes>(null);

  const openDeleteModal = () => {
    if (deleteModal.current) {
      deleteModal.current.changeModal();
    }
  };

  const openNewModal = () => {
    if (newModal.current) {
      newModal.current.changeModal();
    }
  };

  useEffect(() => {
    if (isSuccessfullyCompletedOwner != null) {
      setIsConfirmChangeOwnerModalOpen(false);
      setIsAlertOpen(true);
      if (isSuccessfullyCompletedOwner) {
        setFeedbackMessage("Cambio de propietario realizado con exito");
      } else {
        setFeedbackMessage("Ocurrio un error inesperado");
      }
    } else {
      setFeedbackMessage(null);
    }
  }, [isSuccessfullyCompletedOwner, dispatch]);

  useEffect(() => {
    setIsAlertOpen(false);
    setFeedbackMessage(null);
  }, []);

  return (
    <Box sx={{ padding: "0px" }}>
      {feedbackMessage != null && isSuccessfullyCompletedOwner !== null && (
        <Collapse in={isAlertOpen}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsAlertOpen(false);
                  setFeedbackMessage(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity={isSuccessfullyCompletedOwner ? "success" : "error"}
          >
            {feedbackMessage}
          </Alert>
        </Collapse>
      )}
      <Box className="No-padding">
        {shouldShowNewUser && (
          <Box>
            <Box sx={{ display: "flow-root" }}>
              <Button
                onClick={() => openNewModal()}
                variant="outlined"
                color="secondary"
                size="small"
                className="Button-new-user"
                startIcon={<AddCircleOutlineOutlinedIcon />}
              >
                <Box style={{ fontWeight: "bold", margin: 0 }}>
                  Nuevo usuario
                </Box>
              </Button>
            </Box>
            <Box>
              <hr
                style={{ backgroundColor: "#E5E7EB", height: "1px", border: 0 }}
              ></hr>
            </Box>
          </Box>
        )}

        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table className="Table-invoices" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Correo</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Rol</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mappUsers().map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">{row.email}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{translateRoles(row.rol)}</TableCell>
                  <TableCell>
                    {showDetail ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(_) => {
                          if (onClickDetail) {
                            onClickDetail(row.userId);
                          }
                        }}
                      >
                        Ver detalle
                      </Button>
                    ) : row.rol != "owner" ? (
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        className="Button-xs"
                        startIcon={<DeleteOutlineIcon />}
                        onClick={() => {
                          openDeleteModal();
                          dispatch(setRelationshipSelectId(row.id));
                        }}
                      >
                        <Box style={{ fontWeight: "bold", margin: 0 }}>
                          Eliminar
                        </Box>
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className="Button-xs"
                        startIcon={<EditOutlinedIcon />}
                        onClick={() => {
                          setIsChangeOwnerModalOpen(true);
                        }}
                      >
                        <Box style={{ fontWeight: "bold", margin: 0 }}>
                          Cambiar
                        </Box>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <ModalAction
        shouldShowNewUser={shouldShowNewUser}
        idServiceAccount={idServiceAccount}
        showDetail={showDetail}
        onClickDetail={onClickDetail}
        ref={deleteModal}
      />
      <ModalNewUser idServiceAccount={idServiceAccount} ref={newModal} />
      <ModalChangeOwner
        handleClose={() => {
          setIsChangeOwnerModalOpen(false);
          dispatch(setOwnerIdSelected(""));
        }}
        isOpen={isChangeOwnerModalOpen}
        onSave={() => {
          setIsChangeOwnerModalOpen(false);
          setIsConfirmChangeOwnerModalOpen(true);
        }}
      />
      <ModalConfirmChangeOwner
        handleClose={() => {
          setIsConfirmChangeOwnerModalOpen(false);
          dispatch(setOwnerIdSelected(""));
        }}
        isOpen={isConfirmChangeOwnerModalOpen}
        email={
          users.find((value) => value.user_id == ownerIdSelected)?.username ??
          ""
        }
        onSave={() => {
          dispatch(
            changeOwner({
              new_owner_id: ownerIdSelected,
              service_account_id: idServiceAccount,
            })
          );
        }}
      />
    </Box>
  );
};

export default SectionServiceUsers;
