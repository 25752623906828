import { initEnviroment } from "@enerbit/base";
import { PropsAccessManagement } from "./models/accessManagement";
import Routers from "./router/Routers";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export default function Root({
  idServiceAccount,
  shouldShowNewUser,
  showDetail,
  onClickDetail,
}: PropsAccessManagement) {
  return (
    <Routers
      shouldShowNewUser={shouldShowNewUser}
      idServiceAccount={idServiceAccount}
      showDetail={showDetail}
      onClickDetail={onClickDetail}
    />
  );
}
