import { Box, CircularProgress } from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/accessManagement.scss";
import {
  isLoading,
  setUsersServiceAccount,
  smartServiceAccountInitialuser,
} from "../../features/accessManagement/accessManagement";
import { PropsAccessManagement } from "../../models/accessManagement";
import { AppDispatch } from "../../store/store";
import SectionServiceUsers from "./components/reusable/SectionServiceUsers";

const ServiceAccount = ({
  idServiceAccount,
  shouldShowNewUser,
  showDetail,
  onClickDetail,
}: PropsAccessManagement) => {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(isLoading);

  useEffect(() => {
    if (idServiceAccount != "") {
      dispatch(smartServiceAccountInitialuser(idServiceAccount));
    } else {
      dispatch(setUsersServiceAccount([]));
    }
  }, []);

  if (idServiceAccount == "") {
    return <Box></Box>;
  }

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <SectionServiceUsers
          shouldShowNewUser={shouldShowNewUser}
          idServiceAccount={idServiceAccount}
          showDetail={showDetail}
          onClickDetail={onClickDetail}
        />
      )}
    </Box>
  );
};

export default ServiceAccount;
