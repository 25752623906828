import { CustomModal, theme, LoadingButton, CustomAlert, Box, Stack, CustomModalTypes } from "@enerbit/base";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRelationship, setResetVars, successRelationship, isLoadingUser} from "../../../../features/accessManagement/accessManagement";
import { PropsAccessManagement } from "../../../../models/accessManagement";
import { AppDispatch } from "../../../../store/store";

const ModalAction = forwardRef(({idServiceAccount}:PropsAccessManagement, ref) => {
  const dispatch = useDispatch<AppDispatch>();
  const forgotPassword = useRef<CustomModalTypes>(null);
  const successRelationshipModal = useSelector(successRelationship);
  const loading = useSelector(isLoadingUser);

  useImperativeHandle(ref, () => ({
    changeModal() {
      if(forgotPassword.current)
      {
        forgotPassword.current.changeModal();
      }
    },
  }));

  return ( 
    <CustomModal ref={forgotPassword} 
      onClose={() => {
        dispatch(setResetVars());
      }}
      dialogContent={
        <Box>
          <Box 
            className="Title-modal" 
            color={theme.palette.primary.main}
          >
            ¿Esta seguro de eliminar?
          </Box>
          <div>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                variant="contained"
                color="secondary"
                size="small"
                type="submit"
                className="Loading-button"
                sx={{width: "100%"}}
                loading={false}
              >
                <Box style={{fontWeight: "bold", margin: 0}}>Cancelar</Box>
              </LoadingButton> 
              <LoadingButton
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                className="Loading-button"
                sx={{width: "100%"}}
                loading={loading}
                onClick={() => dispatch(deleteRelationship(idServiceAccount))}
              >
                <Box style={{fontWeight: "bold", margin: 0}}>Eliminar</Box>
              </LoadingButton>
            </Stack>
          </div>
          { successRelationshipModal && (
            <CustomAlert 
              onClose={() => dispatch(setResetVars())}
              sx={{marginTop:"20px",backgroundColor: "#d1fadf !important" }}
              severity="success"
              text={successRelationshipModal}
            />
          )}
      </Box>
      }
    />
  );
})

export default ModalAction;
