import {
  Box,
  CustomAlert,
  CustomModal,
  CustomModalTypes,
  InputLabel,
  LoadingButton,
  MenuItem,
  TextField,
  useFormik,
  yup,
} from "@enerbit/base";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hasError,
  hasErrorUser,
  isLoadingUser,
  setResetVars,
  successRelationship,
  validateUserRelationship,
} from "../../../../features/accessManagement/accessManagement";
import { RolesName, translateRoles } from "../../../../helpers/helperUsers";
import { DataExistsUser } from "../../../../models/accessManagement";
import { AppDispatch } from "../../../../store/store";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Correo no es válido")
    .required("Correo es requerido"),
  role: yup.string().required("Rol es requerido"),
});

const ModalNewUser = forwardRef(({ idServiceAccount }: any, ref) => {
  const dispatch = useDispatch<AppDispatch>();

  const hasErrorModal = useSelector(hasError);
  const loading = useSelector(isLoadingUser);
  const hasErrorUserModal = useSelector(hasErrorUser);
  const successRelationshipModal = useSelector(successRelationship);

  const newUserModal = useRef<CustomModalTypes>(null);

  useImperativeHandle(ref, () => ({
    changeModal() {
      if (newUserModal.current) {
        newUserModal.current.changeModal();
      }
    },
  }));

  const initialValues: DataExistsUser = {
    email: "",
    role: "",
    idServiceAccount: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: DataExistsUser) => {
      values.idServiceAccount = idServiceAccount;
      dispatch(validateUserRelationship(values));
    },
  });

  return (
    <CustomModal
      ref={newUserModal}
      onClose={() => {
        dispatch(setResetVars());
        formik.resetForm();
      }}
      dialogContent={
        <>
          <form onSubmit={formik.handleSubmit}>
            <Box className="TextField-without-border-radius">
              <InputLabel shrink className="Input-label">
                <Box sx={{ marginTop: "10px" }}>Correo eléctronico</Box>
              </InputLabel>
              <TextField
                name="email"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                sx={{ width: "100%" }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
            <Box className="TextField-without-border-radius">
              <InputLabel shrink className="Input-label">
                <Box sx={{ marginTop: "10px" }}>Role</Box>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                name="role"
                select
                sx={{ width: "100%" }}
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role && formik.errors.role}
              >
                {Object.keys(RolesName)
                  .filter((value) => value != RolesName.owner)
                  .map((option) => (
                    <MenuItem key={option} value={option}>
                      {translateRoles(option as RolesName)}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <LoadingButton
                variant="contained"
                color="secondary"
                size="small"
                type="submit"
                className="Loading-button"
                sx={{ width: "100%" }}
                loading={loading}
              >
                <Box style={{ fontWeight: "bold", margin: 0 }}>Enviar</Box>
              </LoadingButton>
            </Box>
            {(hasErrorUserModal || hasErrorModal) && (
              <CustomAlert
                onClose={() => dispatch(setResetVars())}
                sx={{
                  marginTop: "20px",
                  backgroundColor: "#fee4e2 !important",
                }}
                severity="error"
                text={"Ocurrió un error"}
              />
            )}
            {successRelationshipModal && (
              <CustomAlert
                onClose={() => dispatch(setResetVars())}
                sx={{
                  marginTop: "20px",
                  backgroundColor: "#d1fadf !important",
                }}
                severity="success"
                text={successRelationshipModal}
              />
            )}
          </form>
        </>
      }
    />
  );
});

export default ModalNewUser;
