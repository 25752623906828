import { api } from "@enerbit/base";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RolesName } from "../../helpers/helperUsers";
import {
  AccessManagementState,
  ChangeOwnerResponse,
  ChangeOwnershipProps,
  DataExistsUser,
  ServicesAccount,
  ServicesAccountUser,
  ValidateUserRelationships,
} from "../../models/accessManagement";
import { StateStorage } from "../../models/stateStorage";

export const smartUsers = async (idServiceAccount: string) => {
  const res = await api.get(
    "accounts/service-accounts-relationships/?service_account_id=" +
      idServiceAccount +
      "&page=0&size=50"
  );
  let items = await getUsersSmartServices([...res.data.items]);
  return items;
};

export const smartServiceAccountuser = createAsyncThunk(
  "serviceAccount/smartUser",
  smartUsers
);

export const smartServiceAccountInitialuser = createAsyncThunk(
  "serviceAccount/smartUserInitial",
  smartUsers
);

const getUsersSmartServices = async (items: ServicesAccountUser[]) => {
  let userServices = [...items];
  const usersPromises = userServices.map(user => api.get("/users/app-users/" + user.user_id));
  const usersResponse = await Promise.allSettled(usersPromises);
  const usersInfo = usersResponse
    .filter((resp) => resp.status === "fulfilled")
    .map((resp: any) => resp.value.data);

  for (const user of userServices) {
    const userInfo = usersInfo.find((userInfo) => userInfo.id === user.user_id);
    user.username = userInfo?.username ?? "DESCONOCIDO";
    user.name = userInfo
      ? userInfo.pii?.names + " " + (userInfo.pii?.last_names ?? "")
      : "DESCONOCIDO";
  }

  return userServices;
};

export const validateUserRelationship = createAsyncThunk(
  "serviceAccount/validateUserRelationship",
  async (dataExistsUser: DataExistsUser, thunkAPI) => {
    let user = (await thunkAPI.dispatch(
      existsUser(dataExistsUser)
    )) as ValidateUserRelationships;
    if (!user?.error)
      await thunkAPI.dispatch(setRelationshipUser(dataExistsUser));
  }
);

export const existsUser = createAsyncThunk(
  "serviceAccount/existsUser",
  async (dataExistsUser: DataExistsUser) => {
    const { email } = dataExistsUser;
    const existUser = await api.get(
      "/users/app-users/?username=" + encodeURIComponent(email)
    );
    return existUser.data[0]?.id;
  }
);

export const deleteRelationship = createAsyncThunk(
  "serviceAccount/deleteRelationship",
  async (idServiceAccount: string, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const existUser = await api.delete(
      "/accounts/service-accounts-relationships/" +
        state.accessManagement.relationshipSelectId +
        "/"
    );
    await thunkAPI.dispatch(smartServiceAccountuser(idServiceAccount));
    return existUser.data;
  }
);

export const setRelationshipUser = createAsyncThunk(
  "serviceAccount/relationshipUser",
  async (dataExistsUser: DataExistsUser, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const { role, idServiceAccount } = dataExistsUser;
    const existUser = await api.post(
      "/accounts/service-accounts-relationships/",
      {
        user_id: state.accessManagement.userExits,
        service_account_id: idServiceAccount,
        rol_name: role,
      }
    );
    await thunkAPI.dispatch(smartServiceAccountuser(idServiceAccount));
    return existUser.data;
  }
);

export const changeOwner = createAsyncThunk(
  "serviceAccount/change-account-relashionship",
  async (changeOwnershipProps: ChangeOwnershipProps) => {
    const response = await api.post(
      "/accounts/change-account-ownership/",
      null,
      {
        params: changeOwnershipProps,
      }
    );
    return response.data;
  }
);

const initialState: AccessManagementState = {
  hasError: false,
  hasErrorUser: false,
  isLoading: false,
  isLoadingUser: false,
  isChangingOwner: false,
  isSuccessfullyCompletedOwner: null,
  relationshipSelectId: "",
  userExits: "",
  successRelationship: "",
  usersServiceAccount: [],
  ownerIdSelected: "",
};

export const accessManagementSlice = createSlice({
  name: "accessManagement",
  initialState,
  reducers: {
    setUsersServiceAccount: (state, { payload }) => {
      state.usersServiceAccount = payload;
    },
    setRelationshipSelectId: (state, { payload }) => {
      state.relationshipSelectId = payload;
    },
    setResetVars: (state) => {
      state.hasError = false;
      state.hasErrorUser = false;
      state.successRelationship = "";
      state.userExits = "";
      state.isLoadingUser = false;
    },
    setOwnerIdSelected: (state, action) => {
      state.ownerIdSelected = action.payload;
    },
    cleanOwnerIdSelected: (state) => {
      state.ownerIdSelected = "";
    },
    resetFlagConfirmOwner: (state) => {
      state.isSuccessfullyCompletedOwner = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteRelationship.pending.type, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(deleteRelationship.fulfilled.type, (state) => {
        state.isLoadingUser = false;
        state.successRelationship = "Se borró el usuario exitosamente";
      })
      .addCase(deleteRelationship.rejected.type, (state) => {
        state.isLoadingUser = false;
        state.hasError = true;
      })
      .addCase(setRelationshipUser.pending.type, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(setRelationshipUser.fulfilled.type, (state) => {
        state.isLoadingUser = false;
        state.successRelationship =
          "Se agregó el usuario a la relación exitosamente";
      })
      .addCase(setRelationshipUser.rejected.type, (state) => {
        state.isLoadingUser = false;
        state.hasError = true;
      })
      .addCase(existsUser.pending.type, (state) => {
        state.isLoadingUser = true;
        state.hasErrorUser = false;
      })
      .addCase(
        existsUser.fulfilled.type,
        (state, action: PayloadAction<string>) => {
          state.userExits = action.payload;
          state.isLoadingUser = false;
          state.hasErrorUser = false;
        }
      )
      .addCase(existsUser.rejected.type, (state) => {
        state.isLoadingUser = false;
        state.hasErrorUser = true;
      })
      .addCase(smartServiceAccountuser.pending.type, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(
        smartServiceAccountuser.fulfilled.type,
        (state, action: PayloadAction<ServicesAccount[]>) => {
          state.usersServiceAccount = action.payload;
          state.isLoadingUser = false;
        }
      )
      .addCase(smartServiceAccountuser.rejected.type, (state) => {
        state.isLoadingUser = false;
        state.hasError = true;
      })
      .addCase(smartServiceAccountInitialuser.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        smartServiceAccountInitialuser.fulfilled.type,
        (state, action: PayloadAction<ServicesAccount[]>) => {
          state.usersServiceAccount = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(smartServiceAccountInitialuser.rejected.type, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(changeOwner.pending.type, (state) => {
        state.isChangingOwner = true;
        state.isSuccessfullyCompletedOwner = null;
      })
      .addCase(
        changeOwner.fulfilled.type,
        (state, action: PayloadAction<ChangeOwnerResponse>) => {
          state.isSuccessfullyCompletedOwner = true;
          state.isChangingOwner = false;
          state.usersServiceAccount = state.usersServiceAccount.map((user) => {
            if (user.user_id == action.payload.old_owner.user_id) {
              return { ...user, rol: action.payload.old_owner.rol };
            }
            if (user.user_id == action.payload.new_owner.user_id) {
              return { ...user, rol: action.payload.new_owner.rol };
            }
            return user;
          });
          state.usersServiceAccount = state.usersServiceAccount.sort((x, y) => {
            return x.rol.name == RolesName.owner
              ? -1
              : y.rol.name == RolesName.owner
              ? 1
              : 0;
          });
        }
      )
      .addCase(changeOwner.rejected.type, (state) => {
        state.isSuccessfullyCompletedOwner = false;
        state.isChangingOwner = false;
      });
  },
});

export const {
  setResetVars,
  setUsersServiceAccount,
  setRelationshipSelectId,
  setOwnerIdSelected,
  cleanOwnerIdSelected,
  resetFlagConfirmOwner,
} = accessManagementSlice.actions;

export const hasError = (state: StateStorage) =>
  state.accessManagement.hasError;
export const successRelationship = (state: StateStorage) =>
  state.accessManagement.successRelationship;
export const hasErrorUser = (state: StateStorage) =>
  state.accessManagement.hasErrorUser;
export const isLoading = (state: StateStorage) =>
  state.accessManagement.isLoading;
export const isLoadingUser = (state: StateStorage) =>
  state.accessManagement.isLoadingUser;
export const usersServiceAccount = (state: StateStorage) =>
  state.accessManagement.usersServiceAccount;
export const ownerIdSelected = (state: StateStorage) =>
  state.accessManagement.ownerIdSelected;

export default accessManagementSlice.reducer;
